import { Controller } from "@hotwired/stimulus";
import debounce from 'debounce';

export default class extends Controller {
  static targets = ["interactionType", "report", "userids", "leadids", "date", "duration"]

  connect() {
    this.save = debounce(this.save.bind(this), 1000);
  }

  handleChange(event) {
    this.save();
  }

  save() {
    // Vérifiez d'abord l'URL actuelle
    const currentUrl = window.location.href;

    // Récupérer l'ID de l'interaction depuis le champ caché
    const interactionId = document.getElementById("interaction-id").value;

    // Construire l'URL de mise à jour en retirant éventuellement "/edit"
    const updateUrl = `/interactions/${interactionId}`;

    const interactionTypeId = this.interactionTypeTarget.value;
    const userIds = Array.from(this.useridsTarget.selectedOptions).map(option => option.value);
    const leadIds = Array.from(this.leadidsTarget.selectedOptions).map(option => option.value);
    const date = this.dateTarget.value;
    const report = this.reportTarget.value;
    const duration = this.durationTarget.value;

    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(updateUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        interaction: {
          interaction_type_id: interactionTypeId,
          user_ids: userIds,
          lead_ids: leadIds,
          date: date,
          report: report,
          duration : duration
        }
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(data => {
      if (data.status === "success") {
        console.log("Interaction updated:", data.id);
      } else {
        console.error("Error updating interaction:", data.errors);
      }
    })
    .catch(error => {
      console.error("Error auto-saving:", error);
    });
  }
}
