import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tomselect-index-companies"
export default class extends Controller {
  connect() {
    new TomSelect('#ex-checkbox-options',{
      plugins: ['checkbox_options'],
    });
  }
}
