import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="filters-advisors"
export default class extends Controller {
  static targets = ["form", "input", "list", "qualified", "notQualified", "priority", "users", "moreFilters", "textLessFilters", "textMoreFilters"]

  connect() {
    console.log("Hello from filter advisors controller")
    const tom = new TomSelect('#usersIds-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tom.settings.placeholder = "Chargés de relation";
    tom.inputState();
    new TomSelect("#categorydropdown",{
      create: true,
      sortField: {
        field: "text",
        allowEmptyOption: true,
        direction: "asc"
      }
    });
  }

  updateResults() {
    const url = new URL(this.formTarget.action);
    const params = new URLSearchParams(url.search);

    params.set('page', 1);

    params.set('query', this.inputTarget.value);
    params.set('category', this.priorityTarget.value);

    // récupère tous les IDs sélectionnés
    console.log(this.usersTarget)
    const selectedUserIds = Array.from(this.usersTarget.selectedOptions).map(option => option.value);
    selectedUserIds.forEach(userId => params.append('user_ids[]', userId));

    let statutValue;
    if (this.qualifiedTarget.checked) {
      statutValue = this.qualifiedTarget.value;
    } else if (this.notQualifiedTarget.checked) {
      statutValue = this.notQualifiedTarget.value;
    }
    params.set('statut', statutValue);

    url.search = params.toString();
    this.makeFetchRequest(url);
  }

  makeFetchRequest(url) {
    fetch(url, {headers: {"Accept": "text/plain"}})
      .then(response => response.text())
      .then((data) => {
        this.listTarget.outerHTML = data;
        // Potentiellement, vous devez réinitialiser ou réappliquer les plugins JS si nécessaire
      })
  }

  showFilters() {
    console.log("more filters !")
    this.moreFiltersTarget.classList.toggle("d-none");
    this.textLessFiltersTarget.classList.toggle("d-none");
    this.textMoreFiltersTarget.classList.toggle("d-none");
  }
}
