import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
  }

  submit(event) {
    event.preventDefault();
    const form = event.target;
    const url = form.action;
    const formData = new FormData(form);

    fetch(url, {
      method: 'POST',
      body: formData,
      headers: { 'Accept': 'application/json' },
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        // Gestion du succès, fermez le canvas et rafraîchissez la page ou une partie de celle-ci.
      } else {
        response.json().then(data => {
          document.getElementById('form_errors').innerHTML = this.renderErrors(data.errors);
        });
      }
    }).catch(error => console.error("Erreur lors de la soumission du formulaire:", error));
  }

  renderErrors(errors) {
    return errors.map(error => `<div class="alert alert-danger">${error}</div>`).join('');
  }
}
