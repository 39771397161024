import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {

  connect() {
    console.log("hello from multiselect controller")
    var tomSelectInput = document.querySelector('.ts-control');
    new TomSelect(this.element,{
      maxItems: 6,
      plugins: {
        remove_button:{
          title:'Remove this item',
        }
      },
      persist: false,
      create: true,
      })
    }

  }
