import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.tomSelect = new TomSelect(this.inputTarget, {
      valueField: 'value', // Vous pourriez ne pas avoir besoin de cette ligne si 'value' n'est pas utilisé pour la redirection
      labelField: 'text',
      searchField: 'text',
      load: (query, callback) => {
        fetch(`/leads/autocomplete.json?search=${encodeURIComponent(query)}`)
          .then(response => response.json())
          .then(json => callback(json))
          .catch(err => callback());
      },
      onChange: (value) => {
        // Utiliser l'objet des options pour accéder à l'URL
        const selectedItem = this.tomSelect.options[value];
        if (selectedItem && selectedItem.url) {
          window.location.href = selectedItem.url;
        }
      }
    });
  }
}
