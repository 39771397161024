import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {

  connect() {
    var tomSelectInput = document.querySelector('.ts-control');
    new TomSelect(this.element,{
      create: true,
      sortField: {
      field: "text",
      direction: "asc"
      }
    }
    )
  }


}
