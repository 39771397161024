import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="family-user"
export default class extends Controller {
  static targets = ["user", "role"];

  connect() {
    console.log("FamilyUser controller connected");
  }

  edit(event) {
    const userId = event.currentTarget.dataset.userId;
    const role = event.currentTarget.dataset.role;
    const familiesUserId = event.currentTarget.dataset.familiesUserId;

    const modal = document.getElementById('linkFamilyUsersModal');
    const form = modal.querySelector('form');

    const userIdInput = form.querySelector('[name="families_user[user_id]"]');
    const roleSelect = form.querySelector('[name="families_user[role]"]');

    console.log(userId, role, familiesUserId, modal, form, userIdInput, roleSelect)

    if (userIdInput && roleSelect) {
      userIdInput.value = userId;
      roleSelect.value = role;
      console.log(userIdInput.value, roleSelect.value)
    } else {
      console.error('Cannot find form inputs');
    }

    form.action = `/families_users/${familiesUserId}`;
    form.method = 'post'; // HTML forms always use post, Rails will handle _method for PATCH.

    const modalTitle = modal.querySelector('.modal-title');
    modalTitle.textContent = 'Éditer le rôle';

    const modalInstance = bootstrap.Modal.getOrCreateInstance(modal);
    modalInstance.show();
  }



  delete(event) {
    const userId = event.currentTarget.getAttribute('data-user-id');
    const familyId = this.element.dataset.familyId;

    if(confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur de la famille ?")) {
      // Effectuer une requête fetch pour supprimer l'association
      fetch(`/families_users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ user_id: userId, family_id: familyId })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.text();  // Utilisez .text() au lieu de .json() si vous attendez une réponse vide
      })
      .then(data => {
        if (data) {
          const result = JSON.parse(data); // Parsez le JSON seulement s'il y a des données
          console.log(result);
        }
        location.reload(); // pour simplifier, rechargez la page
      })
      .catch(error => console.error('Error:', error));
    }
  }
}
