// app/javascript/controllers/date_selector_controller.js
import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["date"];

  connect() {
    console.log("Date selector connected");
    const tom = new TomSelect('#date-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
      maxOptions: null,
    });
    tom.settings.placeholder = "Date";
    tom.inputState();
  }

  changeDate(event) {
    const selectedDate = event.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set("date", selectedDate);
    window.location.href = url.toString();
  }
}
