import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input", "list"]

  connect() {
    console.log("Hello from filters-distribution-interactions controller")
  }

  updateResults() {
    const url = new URL(this.formTarget.action);
    const params = new URLSearchParams(url.search);
    params.set('page', 1);
    params.set('query', this.inputTarget.value);

    const unopenedOnlyCheckbox = this.formTarget.querySelector('input[name="unopened_only"]');
    if (unopenedOnlyCheckbox && unopenedOnlyCheckbox.checked) {
      params.set('unopened_only', '1');
    } else {
      params.delete('unopened_only');
    }

    url.search = params.toString();
    this.makeFetchRequest(url);
  }

  makeFetchRequest(url) {
    fetch(url, { headers: { "Accept": "text/plain" } })
      .then(response => response.text())
      .then((data) => {
        this.listTarget.outerHTML = data;
        // Réinitialiser ou réappliquer les plugins JS si nécessaire
      })
  }
}
