import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="filters-orders"
export default class extends Controller {
  static targets = ["input", "form", "products", "shareclasses", "list", "textLessFilters", "textMoreFilters", "moreFilters", "navsdates", "operationname", "tagtype"];

  connect() {
    console.log("Hello from filter orders controller");

    new TomSelect('#shareclasses-options', {
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
      placeholder: "Filtrer par part"
    });

    new TomSelect('#products-options', {
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
      placeholder: "Filtrer par fonds"
    });

    new TomSelect('#navsdates-options', {
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
      placeholder: "Filtrer par date",
      maxOptions: null
    });

    new TomSelect('#operationname-options', {
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
      placeholder: "Filtrer par type d'opération",
      maxOptions: null
    });

    new TomSelect('#tagtype-options', {
      hidePlaceholder: true,
      placeholder: "Filtrer par type de tag"
    });
  }

  updateResults() {
    const url = new URL(this.formTarget.action);
    const params = new URLSearchParams(url.search);
    console.log("update results from filters orders controller");
    console.log("coucou");
    params.set('page', 1);
    console.log("query", this.inputTarget.value);
    params.set('query', this.inputTarget.value);
    console.log("params", params);

    const selectedProductsIds = Array.from(this.productsTarget.selectedOptions).map(option => option.value);
    selectedProductsIds.forEach(productId => params.append('products_ids[]', productId));

    const selectedShareclassesIds = Array.from(this.shareclassesTarget.selectedOptions).map(option => option.value);
    selectedShareclassesIds.forEach(shareclassId => params.append('share_class_ids[]', shareclassId));

    const selectedNavsdates = Array.from(this.navsdatesTarget.selectedOptions).map(option => option.value);
    selectedNavsdates.forEach(navdate => params.append('nav_dates[]', navdate));

    const selectedOperationname = Array.from(this.operationnameTarget.selectedOptions).map(option => option.value);
    selectedOperationname.forEach(operationname => params.append('operation_name[]', operationname));

    const selectedTagtype = this.tagtypeTarget.value;
    if (selectedTagtype) {
      params.append('tag_type', selectedTagtype);
    }

    url.search = params.toString();
    this.makeFetchRequest(url);
    fetch(url, { headers: { "Accept": "text/plain" } })
    .then(response => response.text())
    .then((data) => {
      this.listTarget.outerHTML = data
    })
  }

  makeFetchRequest(url) {
    fetch(url, { headers: { "Accept": "text/plain" } })
      .then(response => response.text())
      .then((data) => {
        this.listTarget.outerHTML = data;
      });
  }

  showFilters() {
    console.log("more filters !");
    this.moreFiltersTarget.classList.toggle("d-none");
    this.textLessFiltersTarget.classList.toggle("d-none");
    this.textMoreFiltersTarget.classList.toggle("d-none");
  }
}
