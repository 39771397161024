import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="missions"
export default class extends Controller {
  static targets = ["missionsContainer", "details"]

  connect() {
    console.log("Hello, Stimulus fetch 2!");
  }

  loadTasks(event) {
    event.preventDefault(); // Pour empêcher toute action par défaut.
    const missionId = event.currentTarget.dataset.missionId;
    // const leadId = event.currentTarget.dataset.leadId;
    const leadId = this.element.dataset.leadId;

    console.log(`Chargement des tâches pour la mission ${missionId} du lead ${leadId}.`)
    const url = `/leads/${leadId}/missions/${missionId}/tasks`;

    // Ajuster la classe du container des missions pour la mise en page.
    this.missionsContainerTarget.classList.add('col-9');
    this.detailsTarget.classList.remove('d-none'); // Assurez-vous que votre conteneur de détails n'est pas caché par défaut.

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(html => {
        this.detailsTarget.innerHTML = html;
      })
      .catch(e => {
        console.error('Erreur lors du chargement des tâches:', e);
        // Gérez l'erreur, par exemple en affichant un message d'erreur à l'utilisateur.
      });
  }

  editMission(event) {
    event.stopPropagation(); // Empêche le clic de se propager au conteneur parent.
  }
}
