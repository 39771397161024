import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="filters-leads"
export default class extends Controller {
  static targets = ["form", "input", "list", "familyOffice", "externalDistribution", "priority", "users", "moreFilters", "stepDropdown", "textLessFilters", "textMoreFilters"]

  connect() {
    console.log("Hello from filter leads controller")
    const tom = new TomSelect('#usersIds-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tom.settings.placeholder = "Chargés de relation";
    tom.inputState();
    const tomsteps = new TomSelect('#steps-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tomsteps.settings.placeholder = "Etapes";
    tomsteps.inputState();
  }

  updateResults() {
    const url = new URL(this.formTarget.action);
    const params = new URLSearchParams(url.search);

    params.set('page', 1);
    params.set('query', this.inputTarget.value);

    // récupère tous les IDs sélectionnés
    const selectedUserIds = Array.from(this.usersTarget.selectedOptions).map(option => option.value);
    selectedUserIds.forEach(userId => params.append('user_ids[]', userId));

    const selectedStepIds = Array.from(this.stepDropdownTarget.selectedOptions).map(option => option.value);
    selectedStepIds.forEach(stepId => params.append('step_ids[]', stepId));

    let categoryValue;
    if (this.familyOfficeTarget.checked) {
      categoryValue = this.familyOfficeTarget.value;
    } else if (this.externalDistributionTarget.checked) {
      categoryValue = this.externalDistributionTarget.value;
    }
    params.set('category', categoryValue);

    url.search = params.toString();
    this.makeFetchRequest(url);
  }

  makeFetchRequest(url) {
    fetch(url, {headers: {"Accept": "text/plain"}})
      .then(response => response.text())
      .then((data) => {
        this.listTarget.outerHTML = data;
      })
    }

  showFilters() {
    console.log("more filters !")
    this.moreFiltersTarget.classList.toggle("d-none");
    this.textLessFiltersTarget.classList.toggle("d-none");
    this.textMoreFiltersTarget.classList.toggle("d-none");
  }
}
