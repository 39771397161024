import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="button"
export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Hello, Stimulus!")
  }

  changeColorAndDisable() {
    this.buttonTarget.style.backgroundColor = "#03CBA7";
    this.buttonTarget.disabled = true; // Désactiver le bouton
  }

}
