import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["form", "list", "input", "checkbox", "formcheck", "more-filters"];

  connect() {
    console.log(this.checkboxTarget);
    console.log(this.formcheckTarget);
    const tom = new TomSelect('#ex-checkbox-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tom.settings.placeholder = "Activité";
    tom.inputState();
  }

  async update() {
    const url = new URL(this.formTarget.action);
    url.searchParams.append("query", this.inputTarget.value);

    const checkedValues = this.getTomSelectValues();
    if (checkedValues.length > 0) {
      checkedValues.forEach((value) => {
        url.searchParams.append("business_type_ids[]", value);
      });
    }

    try {
      const response = await fetch(url, { headers: { "Accept": "application/json" } });
      const { companiesList, markers } = await response.json();
      this.listTarget.outerHTML = companiesList;
      const mapController = this.application.getControllerForElementAndIdentifier(document.getElementById("map-container"), "map");
      mapController.markersValue = markers;
      await mapController.updateMarkers();
    } catch (error) {
      console.error("Une erreur s'est produite lors de la mise à jour des entreprises :", error);
    }
  }

  getCheckedValues() {
    return Array.from(this.checkboxTargets)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
  }

  updatebusiness() {
    const url = new URL(this.formcheckTarget.action);
    url.searchParams.append("query", this.inputTarget.value);

    const checkedValues = this.getTomSelectValues();
    if (checkedValues.length > 0) {
      checkedValues.forEach((value) => {
        url.searchParams.append("business_type_ids[]", value);
      });
    }

    fetch(url, { headers: { "Accept": "application/json" } })
      .then(response => response.json())
      .then(({ companiesList, markers }) => {
        this.listTarget.outerHTML = companiesList;
        const mapController = this.application.getControllerForElementAndIdentifier(document.getElementById("map-container"), "map");
        mapController.markersValue = markers;
        mapController.updateMarkers();
      });
  }

  getTomSelectValues() {
  const select = document.getElementById("ex-checkbox-options");
  return select.tomselect.items;
}
}
