// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import "bootstrap/dist/js/bootstrap.bundle"
import "./controllers"

import "jquery"
import "chartkick/chart.js"
// import "chartkick"
// import "Chart.bundle"

// function waitForBootstrapAndInitTooltips() {
//   if (typeof bootstrap === 'undefined') {
//     setTimeout(waitForBootstrapAndInitTooltips, 100); // Attendez 100ms puis réessayez
//   } else {
//     // Bootstrap est disponible, initialisez vos tooltips
//     const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
//     const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
//     console.log('Tooltips iaaaaaaaaaaaaanaaaitialized.');
//   }
// }

// document.addEventListener('DOMContentLoaded', waitForBootstrapAndInitTooltips);

document.addEventListener('turbo:load', function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      html: true // Active l'interprétation du HTML
    });
  });
});
