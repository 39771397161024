import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["form", "input", "list", "statuses", "users", "moreFilters", "priorities", "textLessFilters", "textMoreFilters", "families"]

  connect() {
    console.log("Hello from filter leads controller")
    const tom = new TomSelect('#usersIds-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tom.settings.placeholder = "Chargés de relation";
    tom.inputState();

    const tompriorities = new TomSelect('#priorities-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tompriorities.settings.placeholder = "Priorités";
    tompriorities.inputState();

    const tomstatuses = new TomSelect('#statuses-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tomstatuses.settings.placeholder = "Statut";
    tomstatuses.inputState();

    const tomFamilies = new TomSelect('#familiesIds-options',{
      plugins: ['checkbox_options'],
      hidePlaceholder: true,
    });
    tomFamilies.settings.placeholder = "Familles";
    tomFamilies.inputState();
  }

  updateResults() {
    const url = new URL(this.formTarget.action);
    const params = new URLSearchParams(url.search);

    params.set('page', 1);
    params.set('query', this.inputTarget.value);

    // Récupère tous les IDs sélectionnés des utilisateurs
    const selectedUserIds = Array.from(this.usersTarget.selectedOptions).map(option => option.value);
    selectedUserIds.forEach(userId => params.append('user_ids[]', userId));

    const selectedFamiliesIds = Array.from(this.familiesTarget.selectedOptions).map(option => option.value);
    selectedFamiliesIds.forEach(familyId => params.append('families_ids[]', familyId));

    // Récupère tous les IDs sélectionnés des priorités
    const selectedPriorities = Array.from(this.prioritiesTarget.selectedOptions).map(option => option.value);
    selectedPriorities.forEach(priority => params.append('priorities[]', priority));

    const selectedStatuses = Array.from(this.statusesTarget.selectedOptions).map(option => option.value);
    selectedStatuses.forEach(status => params.append('statuses[]', status));

    url.search = params.toString();
    this.makeFetchRequest(url);
  }

  makeFetchRequest(url) {
    fetch(url, {headers: {"Accept": "text/plain"}})
      .then(response => response.text())
      .then((data) => {
        this.listTarget.outerHTML = data;
      })
  }

  showFilters() {
    console.log("more filters !")
    this.moreFiltersTarget.classList.toggle("d-none");
    this.textLessFiltersTarget.classList.toggle("d-none");
    this.textMoreFiltersTarget.classList.toggle("d-none");
  }
}
