import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["body", "confirmButton"]

  connect() {
    console.log("Hello from modal controller!")
    console.log("Targets:", this.bodyTarget, this.confirmButtonTarget)
  }

  open(event) {
    event.preventDefault(); // Annule le comportement par défaut du lien ou du bouton
    console.log("Open modal triggered", event)
    const url = event.currentTarget.dataset.url
    const message = event.currentTarget.dataset.message

    console.log("URL:", url)
    console.log("Message:", message)

    this.bodyTarget.textContent = message
    this.confirmButtonTarget.href = url
    $('#deleteModal').modal('show')
  }
}
