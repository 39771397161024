// app/javascript/controllers/echart_controller.js
import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="echart"
export default class extends Controller {
  static values = {
    data: Number,
    productId: Number
  };

  connect() {
    this.chartElement = this.element.querySelector('.chart');
    this.chart = echarts.init(this.chartElement);
    this.renderChart();
    window.addEventListener('resize', this.resizeChart.bind(this));
    console.log('Echart connected');
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeChart.bind(this));
  }

  resizeChart() {
    if (this.chart) {
      this.chart.resize();
    }
  }

  renderChart() {
    const lockedUpShares = this.dataValue;
    const otherShares = 100 - lockedUpShares;

    const lockedUpPercentage = lockedUpShares.toFixed(2);
    const otherPercentage = otherShares.toFixed(2);

    const option = {
      legend: {
        bottom: '0%',
        left: 'center',
        textStyle: {
          color: '#E4E4E7'
        }
      },
      series: [
        {
          name: 'Parts Illiquides',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: '#C9CACD',
            borderWidth: 1
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
            },
            label: {
              show: true,
              fontSize: 15,
              fontWeight: 600,
              fontFamily: 'Inter',
              color: '#E4E4E7'
            }
          },
          labelLine: {
            show: false
          },
          color: ['#5062E8', '#348D3A'], // Explicitly set colors
          data: [
            { value: lockedUpShares, name: `Parts illiquides\n${lockedUpPercentage}%`, itemStyle: { color: "#5062E8" } },
            { value: otherShares, name: `Parts liquides\n${otherPercentage}%`, itemStyle: { color: "#348D3A" } }
          ]
        }
      ]
    };

    this.chart.setOption(option);
  }
}
