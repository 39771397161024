import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interaction-form"
export default class extends Controller {
  static targets = ["addressInput", "interaction"];

  connect() {
    console.log("hello from interaction-form");
    const selectedOption = this.interactionTarget;
    const addressInput = this.addressInputTarget;
  }

  handleChange() {

    const addressInput = this.addressInputTarget;
    const selectedOptionValue = this.interactionTarget.value;
    const selectedOption = Array.from(this.interactionTarget.options).find(option => option.value === selectedOptionValue);
    const interactionName = selectedOption.textContent;

    console.log(interactionName);
    console.log(selectedOptionValue);


    if (interactionName === "Rendez-vous physique" || interactionName === "Evénement") {
      addressInput.classList.remove("d-none");
    } else {
      addressInput.classList.add("d-none");
    }
  }
}
