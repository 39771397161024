import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "detailsGeneral", "detailsInteraction", "show", "hide", "detailsTask", "nextInteractions", "pastInteractions", "toggleIncomes"]

  connect() {
    console.log("connected")
  }

  toggleForm() {
    console.log("toggling form")
    event.preventDefault()
    this.formTarget.classList.toggle("d-none")
  }

  toggleGeneral() {
    event.preventDefault()
    this.detailsGeneralTarget.classList.toggle("d-none");
    this.toggleIncomesTarget.classList.add("d-none");
  }

  toggleInteraction() {
    event.preventDefault()
    this.detailsInteractionTarget.classList.toggle("d-none");
  }

  toggleTask() {
    event.preventDefault()
    this.detailsTaskTarget.classList.toggle("d-none");
  }

  toggleShow() {
    event.preventDefault()
    this.showTarget.classList.toggle("d-none");
  }

  toggleIncomes(event) {
    event.preventDefault()
    this.detailsGeneralTarget.classList.add("d-none");
    this.toggleIncomesTarget.classList.toggle("d-none");
  }

  toggleNextInteractions() {
    this.nextInteractionsTarget.classList.remove("d-none");
    this.pastInteractionsTarget.classList.add("d-none");
  }

  togglePastInteractions() {
    this.nextInteractionsTarget.classList.add("d-none");
    this.pastInteractionsTarget.classList.remove("d-none");
  }


}
